<!--
 * @Author: lg9527 13691080728@126.com
 * @Date: 2024-07-06 21:50:33
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2024-07-18 10:17:24
 * @FilePath: \zky_webVue\src\views\sample\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="m-pg-sub">
    <page-cover code="11" layer="rgba(0,0,0,.2)">
      <!-- <h2 class="m-page-title">{{$t('page.search.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.sample.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <tab-nav
        :data="tabNavData"
        :active.sync="tabNavActiveUrl">
      </tab-nav>
      <b>提示：1.大量数据上传建议使用：标准数据模板录入、用户数据自适应匹配录入 2.少量数据上传建议使用：逐篇录入</b>
      <div class="child-main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/tab-nav'
export default {
  components: {
    TabNav
  },
  data() {
    return {
      tabNavData: [

        {name: this.$t('page.sample.m_tab.add'), url: '/sample/add'},
        {name: this.$t('page.sample.m_tab.importNonStandard'), url: '/sample/import-non-standard'},
        {name: this.$t('page.sample.m_tab.importStandard'), url: '/sample/import-standard'}
      ],
      tabNavActiveUrl: '/sample/add'
    }
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    .child-main {
      // padding: 20px;
      // background-color: #fff;
    }
  }
</style>
